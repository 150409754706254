.type {
  padding: 5px 15px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  background: rgba(7, 162, 135, 0.1);
  color: #07a287;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.items {

}

.item {
  background-color: rgba(51, 51, 51, .05);

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &Name {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    cursor: pointer;

    &.active {
      background-color: rgba(7, 162, 135, 0.1);
    }
  }

  &Content {
    padding: 5px 10px;

    & input {
      width: auto;
    }
  }
}

.organization {
  &All {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &Item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 15px;
    background-color: white;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &Tasks {
    margin-top: 10px;
  }

  &Task {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 5px 0 5px 20px;
    border-top: 1px solid #eee;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &Index {
      font-weight: 600;
    }

    &Description {
      margin-right: auto;
    }

    &Deadline {

    }

    &Delete {
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        opacity: .5;
      }
    }
  }

  &Name {
    margin-right: auto;
  }

  &Btn {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 2px 4px;
    font-size: 8px;
    background-color: rgba(7, 162, 136, 0.1);
    color: #07a287;
    border: 0;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

    span {
      margin-right: 6px;
      font-size: 18px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}