.export__btn {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 8px;
  background-color: #fff;
  border: 0;
  color: #29a0e3;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  &:hover {
    background-color: rgba(104, 104, 104, 0.1);
  }
}