* {
	box-sizing: border-box;
}

.inputContainer {
	width: 200px;

	.input {
		padding: 9px 10px;
		padding-right: 40px;
		width: 100%;
		border: none;
		font-size: 13px;
		border-radius: 8px;
		background: rgba(35, 35, 35, 0.05);
		outline: none;
		margin-right: 20px;
	}

	.icon {
		background-color: transparent;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		&:hover {
			cursor: pointer;
		}
	}
}
