.limit {
	white-space: nowrap;
	.select {
		position: relative;
		font-size: 14px;

		.lang__checked {
			margin-right: 2px;
			overflow: hidden;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
		}

		&ed {
			width: 230px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			padding: 7px;
			transition: all 0.3s ease;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			.chevron {
				margin-left: 4px;
				transition: all 0.3s ease;
				transform: rotate(0);
				-webkit-transform: rotate(0);
				-moz-transform: rotate(0);
				-ms-transform: rotate(0);
				-o-transform: rotate(0);
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				&__active {
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
				}
			}

			&__active {
				background-color: rgba(35, 35, 35, 0.05);
				border-radius: 8px 8px 0 0;
				-webkit-border-radius: 8px 8px 0 0;
				-moz-border-radius: 8px 8px 0 0;
				-ms-border-radius: 8px 8px 0 0;
				-o-border-radius: 8px 8px 0 0;
			}
		}

		&__list {
			position: absolute;
			left: 0;
			width: 100%;
			z-index: 5;
			padding: 0 10px 8px;
			border-radius: 0 0 8px 8px;
			visibility: hidden;
			background-color: #fff;
			transform: translateY(-10px);
			transition: all 0.3s ease;
			opacity: 0;
			-webkit-border-radius: 0 0 8px 8px;
			-moz-border-radius: 0 0 8px 8px;
			-ms-border-radius: 0 0 8px 8px;
			-o-border-radius: 0 0 8px 8px;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-webkit-transform: translateY(-10px);
			-moz-transform: translateY(-10px);
			-ms-transform: translateY(-10px);
			-o-transform: translateY(-10px);

			&__item {
				display: flex;
				padding: 10px 0;
				cursor: pointer;
				&:hover {
					background-color: rgba(100, 100, 100, 0.1);
				}
			}

			&__active {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				-webkit-transform: translateY(0);
				-moz-transform: translateY(0);
				-ms-transform: translateY(0);
				-o-transform: translateY(0);
			}
		}
	}

	@media (min-width: 1440px) {
		.selected,
		.select__list__item {
			width: 300px;
			font-size: 18px !important;
		}
	}
}
