
.search_icon {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
}

.type {
  display: inline-block;
  padding: 5px 15px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  background: rgba(7, 162, 135, 0.1);
  color: #07a287;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}