.modal {
   position: fixed;
   z-index: 20000;
   left: 0;
   top: 0;
   display: grid;
   place-items: center;
   width: 100%;
   height: 100%;
   padding: 30px;
   background-color: rgb(0, 0, 0);
   background-color: rgba(0, 0, 0, 0.4);
   overflow: auto;
}

.content {
   width: 640px;
   padding: 20px;
   background-color: #fefefe;
   border: 1px solid #888;
   border-radius: 10px;
}

.btns {
   display: flex;
   justify-content: flex-end;
   column-gap: 15px;
}