.mark {
	.check__title {
		margin-right: 3px;
		font-size: 14px;
		font-weight: 500;
	}
	.checkbox {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		background-color: rgba(7, 162, 135, 0.1);
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
		cursor: pointer;
		transition: all 0.2s ease;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-ms-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		svg {
			width: 15px;
			font-weight: 400;
			margin: 0 !important;
		}

		&__small {
			width: 25px;
			height: 25px;

			svg {
				width: 12px;
				color: #07a287;
			}

			&__active {
				background-color: #07a287;

				svg {
					color: #fff;
				}

				&:hover {
					background-color: #07a287;
				}
			}
		}

		&__green {
			svg {
				color: #07a287;
			}
			&:hover {
				background-color: rgba(7, 162, 135, 0.7);
				svg {
					color: #fff;
				}
			}

			&__active {
				background-color: #07a287;

				svg {
					color: #fff;
				}

				&:hover {
					background-color: #07a287;
				}
			}
		}

		&__del {
			svg {
				color: #ff5252;
			}
			background-color: rgba(255, 82, 82, 0.1);

			&__active {
				background-color: #ff5252;

				svg,
				path {
					color: #fff !important;
					fill: #fff !important;
					stroke: white;
				}

				&:hover {
					background-color: #ff5252;
				}
			}

			&:hover {
				background-color: rgba(255, 82, 82, 0.7);
				svg {
					color: #fff !important;
				}
			}
		}
	}

	.info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 10px 0;

		h6,
		h5 {
			margin: 0;
		}

		&__card {
			width: 23%;
			background-color: #fff;
			border-radius: 8px;
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;

			input {
				width: 85px;
				margin-left: 10px;
				background-color: #f4f4f4;
				padding: 5px;
				padding-right: 2px;
				font-weight: 500;
				text-align: right;
				border: 0;
				border-radius: 5px;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				-ms-border-radius: 5px;
				-o-border-radius: 5px;
			}

			&__green {
				color: #07a287;
			}

			&__yellow {
				color: #ffb155;
			}

			&__red {
				color: #ff5252;
			}

			&__all {
				color: #232323;
			}
		}
	}

	.comment {
		width: 48.5%;
		background-color: #fff;
		padding: 10px 10px 5px;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;

		label {
			color: #07a287;
			margin: 10px;
		}

		textarea {
			width: 100%;
			padding: 15px;
			background: rgba(196, 196, 196, 0.1);
			border: 1px solid rgba(0, 0, 0, 0.1);
			box-sizing: border-box;
			border-radius: 8px;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;
		}
	}

	.mark {
		margin-left: 40px;
		text-align: center;

		&__item {
			color: #000;
			background-color: #fff;
			padding: 10px;
			text-align: center;
			border-radius: 8px;
			font-weight: 500;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;

			@media (min-width: 1440px) {
				font-size: 16px;
			}

			@media (min-width: 1660px) {
				font-size: 18px;
			}
		}

		input {
			width: 50px;
			font-weight: 500;
			text-align: center;
			margin-right: 5px;
			padding: 5px;
			background-color: #f4f4f4;
			border: 0;
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-ms-border-radius: 5px;
			-o-border-radius: 5px;
		}
	}

	@media (min-width: 1440px) {
		.check__title {
			font-size: 16px;
		}
	}

	@media (min-width: 1900px) {
		.check__title {
			font-size: 18px;
		}
	}
}
