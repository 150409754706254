
.mainContainer {
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 26px 30px !important;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #07A287;
  }
  

  form {
    margin-right: 20px;
  }
}
