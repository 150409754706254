.form {
	label {
		div,
		input {
			font-size: 13px;
		}
	}
	button {
		font-size: 14px;
		padding: 4px 10px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	@media (min-width: 1440px) {
		button, label, input {
			font-size: 15px !important;
		}
	}

	@media (min-width: 1660px) {
		button, label, input {
			font-size: 18px !important;
		}
	}
}

.table {
	.left,
	.right {
		width: 25%;
		text-align: left;
		padding: 15px 0 !important;
	}
	.right {
		width: 75%;
		text-align: right;
	}
}
