.header {
	display: flex;
	height: 60px;
	align-items: center;
	padding: 0 20px;
	justify-content: space-between;
	position: fixed;
	top: 0;
	right: 0;
	background: #fff;
	z-index: 9;
	box-shadow: 0 0 10px rgba(#008e76, 0.1);

	width: calc(100% - 95px);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;

	&__active {
		width: calc(100% - 300px);
	}

	.phone {
		display: flex;
		align-items: center;
		margin: 0 15px;
		p {
			margin: 0;
			margin-left: 10px;
			font-size: 17px;
			font-weight: 500;
		}
	}

	.select {
		position: relative;

		.lang__checked {
			margin-right: 2px;
			overflow: hidden;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
		}

		&ed {
			display: flex;
			width: fit-content;
			cursor: pointer;
			padding: 10px;
			transition: all 0.3s ease;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			.chevron {
				margin-left: 4px;
				transition: all 0.3s ease;
				transform: rotate(0);
				-webkit-transform: rotate(0);
				-moz-transform: rotate(0);
				-ms-transform: rotate(0);
				-o-transform: rotate(0);
				-webkit-transition: all 0.3s ease;
				-moz-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
				-o-transition: all 0.3s ease;
				&__active {
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
				}
			}

			&__active {
				background-color: #fff;
				border-radius: 8px 8px 0 0;
				-webkit-border-radius: 8px 8px 0 0;
				-moz-border-radius: 8px 8px 0 0;
				-ms-border-radius: 8px 8px 0 0;
				-o-border-radius: 8px 8px 0 0;
			}
		}

		&__list {
			position: absolute;
			right: 0;
			width: max-content;
			z-index: 5;
			padding: 0 10px 8px;
			border-radius: 0 0 8px 8px;
			visibility: hidden;
			background-color: #fff;
			transform: translateY(-10px);
			transition: all 0.3s ease;
			opacity: 0;
			-webkit-border-radius: 0 0 8px 8px;
			-moz-border-radius: 0 0 8px 8px;
			-ms-border-radius: 0 0 8px 8px;
			-o-border-radius: 0 0 8px 8px;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			-webkit-transform: translateY(-10px);
			-moz-transform: translateY(-10px);
			-ms-transform: translateY(-10px);
			-o-transform: translateY(-10px);

			&__item {
				display: flex;
				padding: 10px 0;
				cursor: pointer;
				&:hover {
					text-decoration: none !important;
					background-color: rgba($color: #ddd, $alpha: 0.1);
				}
			}

			&__active {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				-webkit-transform: translateY(0);
				-moz-transform: translateY(0);
				-ms-transform: translateY(0);
				-o-transform: translateY(0);
			}

			.acc__icon {
				svg {
					fill: #0056b3;
				}
			}

			.acc__items {
				padding: 5px;
				font-size: 13px;
				cursor: pointer;
				transition: all 0.2s ease;
				-webkit-transition: all 0.2s ease;
				-moz-transition: all 0.2s ease;
				-ms-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;

				&__active {
					color: #fff;
					background-color: #07a287;
				}

				&:hover {
					color: #fff;
					background-color: #07a28867;
					opacity: 0.7;
				}
			}
		}
	}

	@media (min-width: 1440px) {
		.acc__items {
			font-size: 16px !important;
		}
	}
}
