.taskCheckModal {
	.title {
		color: #a7a7a7;
		font-size: 16px;
		margin-bottom: 8px;
	}
	.desc {
		color: #000;
		margin-top: 5px;
	}

	.links {
		width: 100%;
		color: rgba(41, 160, 227, 1);
		padding: 8px;
		text-align: center;
		border-radius: 6px;
		background-color: rgba(41, 160, 227, 0.1);
	}

	.btns {
		display: flex;
		align-items: center;
		padding: 8px 12px;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;

		&__red {
			color: #ff5252;
			background-color: rgba(255, 82, 82, 0.1);
		}

		&__green {
			color: #07a287;
			background-color: rgba(7, 162, 135, 0.1);
		}

		&__circle {
			position: relative;
			width: 15px;
			height: 15px;
			margin-right: 5px;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;

			&__red {
				border: 1px solid #ff5252;

				&__active {
					width: 10px;
					height: 10px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-5px, -5px);
					background-color: #ff5252;
					border-radius: 50%;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					-o-border-radius: 50%;
					-webkit-transform: translate(-5px, -5px);
					-moz-transform: translate(-5px, -5px);
					-ms-transform: translate(-5px, -5px);
					-o-transform: translate(-5px, -5px);
				}
			}

			&__green {
				border: 1px solid #07a287;
				&__active {
					width: 10px;
					height: 10px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-5px, -5px);
					background-color: #07a287;
					border-radius: 50%;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					-o-border-radius: 50%;
					-webkit-transform: translate(-5px, -5px);
					-moz-transform: translate(-5px, -5px);
					-ms-transform: translate(-5px, -5px);
					-o-transform: translate(-5px, -5px);
				}
			}
		}
	}
}
