.filter {
	&__main {
		width: 350px;
		height: 100vh;
		padding: 20px;
		padding-right: 10px;
		position: fixed;
		top: 0;
		right: 0;
		overflow-y: scroll;
		z-index: 15;
		background-color: #fff;
		transition: all 0.3s ease;
		transform: translateX(25em);
		-webkit-transform: translateX(25em);
		-moz-transform: translateX(25em);
		-ms-transform: translateX(25em);
		-o-transform: translateX(25em);
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		&__active {
			transform: translateX(0);
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			-o-transform: translateX(0);
		}
	}

	&__title {
		color: #07a287;
		margin-bottom: 30px;
	}
	&__back {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 14;
		background-color: rgba(0, 0, 0, 0.5);
	}
}
