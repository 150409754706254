.items {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;

	.item {
		width: 30%;
		background-color: rgba(51, 51, 51, 0.03);
		padding: 10px;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
		&__title {
			font-weight: 500;
			margin: 0;
		}
	}
}

.chart {
	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		.circle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;

			&__done {
				background-color: #ef5da8;
			}

			&__cancel {
				background-color: #faebf3;
			}
		}

		p {
			margin: 0;
			margin-left: 5px;
			font-size: 15px;
			font-weight: 500;
		}
	}
}

.task {
	width: 100%;
	margin: 10px 0 !important;
	justify-content: space-between;
	&__item,
	&__num {
		padding: 10px 0 !important;
		text-align: center !important;
		border-radius: 8px;
		font-weight: 500;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
	}
}

.stat {
	margin: 10px 0;
	&__table {
		width: 100%;
		margin: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: rgba(51, 51, 51, 0.05);
		padding: 10px 15px;
		font-size: 14px;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;

		a {
			font-size: 14px;
		}
	}
}
