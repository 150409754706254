.homeCard {
	width: 24%;
	border-radius: 8px;
	padding: 15px 0;
	background-color: royalblue;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;

	&__title {
		padding: 0 15px;
		font-weight: 500;
		color: #fff;
	}

	&__btn {
		width: 100%;
		padding: 5px 15px;
		margin-top: 30px;
		border-radius: 50px;
		background: rgba(255, 255, 255, 0.2);
		color: #fff;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		-ms-border-radius: 50px;
		-o-border-radius: 50px;
		transition: all 0.2s ease;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-ms-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		&:hover {
			opacity: 0.9;
		}
	}

	&__time {
		display: flex;
		justify-content: space-between;
		margin: 8px 5px;
		background-color: #fff;
		padding: 5px 10px;
		border-radius: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		-ms-border-radius: 10px;
		-o-border-radius: 10px;
	}

	&__stop__btn {
		margin: 0 auto !important;
		width: 98%;
		background-color: #ff5252;
	}
}
