.home {
	width: 100%;

	&__info {
		display: flex;
		justify-content: space-between;
	}

	&__body {
		width: 100%;
		display: flex;
		margin: 20px 0;
		justify-content: space-between;

		.body__card {
			width: 49.3333333%;
			background-color: #fff;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
			border-radius: 8px;
			margin-right: 15px;
			padding: 15px;

			&:last-of-type {
				margin-right: 0;
			}

			.card__title {
				min-width: 300px;
				color: #07a287;
				font-weight: 500;
				margin: 0;
			}

			.more__btn {
				color: #29a0e3;
				padding: 5px 10px;
				background: rgba(41, 160, 227, 0.1);
				border-radius: 8px;
			}

			.table {
				tbody {
					tr {
						td {
							border: none;

							.background {
								font-weight: 500;
								margin: 0 auto;
								width: 30px;
								height: 30px;
								line-height: 30px;
								text-align: center;
								border-radius: 50%;
								color: #ef5da8;
								background-color: rgba(239, 93, 168, 0.1);
							}

							.bg__light {
								color: #333;
								background-color: rgba(51, 51, 51, 0.1);
							}

							.progress {
								height: 10px;
								margin-right: 10px;
								position: relative;
								background-color: transparent;
								border-radius: 8px;

								&__bar {
									height: 10px;
									border-radius: 8px;
									position: relative;
								}

								&__value {
									text-align: center;
									width: 35px;
									height: 35px;
									font-size: 15px;
									border-radius: 50%;
									line-height: 35px;
									background: #07a287;
									font-size: 12.5px;
									font-weight: 600;
									color: #fff;
									position: absolute;
									top: 50%;
									display: flex;
									align-items: center;
									justify-content: center;
									transform: translateY(-50%);
									right: -17.5px;
								}
							}
						}
					}
				}

				&__left {
					thead {
						td {
							padding: 15px;
						}
					}
					tbody {
						td {
							padding: 15px;
							height: 60px;
						}
					}
				}

				&__right {
					thead {
						td {
							padding: 15px;
						}
					}
					tbody {
						td {
							padding: 10px 15px;
						}
					}
				}
			}
		}
	}

	@media (min-width: 1440px) {
		.cardItem {
			font-size: 16px !important;
		}

		.progress {
			&__value {
				width: 45px !important;
				height: 45px !important;
				font-size: 15px !important;
				right: -22.5px !important;
			}
		}
	}

	@media (min-width: 1900px) {
		.progress {
			&__value {
				width: 50px !important;
				height: 50px !important;
				font-size: 16px !important;
				right: -25px !important;
			}
		}
	}
}
