.table {
	width: 100%;
	min-height: 100px;
	overflow-x: scroll;

	.noData {
		text-align: center;
		margin-top: 20px;
		color: #b0b7ce;
	}

	&__main {
		width: 100%;
		font-size: 14px;

		thead {
			tr {
				td {
					color: #b0b7ce;
					padding: 10px 15px 0;
					white-space: nowrap;
					font-weight: 500;

					&:first-child {
						padding: 10px 0 0;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 15px;
					font-weight: 500;

					border-bottom: 1px solid #ddebff;
					&:first-child {
						padding: 0;
					}
				}
			}
		}
	}
}

.content__head {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.title {
		width: 80%;
		line-height: 22px;
		margin: 0;
		color: #07a287;
	}

	.btns {
		display: flex;
		align-items: center;

		.export__btn {
			display: flex;
			align-items: center;
			padding: 7px 10px;
			border-radius: 8px;
			background-color: #fff;
			border: 0;
			color: #29a0e3;
			transition: all 0.2s ease;
			-webkit-transition: all 0.2s ease;
			-moz-transition: all 0.2s ease;
			-ms-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;

			&:hover {
				background-color: rgba(104, 104, 104, 0.1);
			}
		}

		.add__btn {
			display: flex;
			align-items: center;
			padding: 7px 10px;
			border: 0;
			border-radius: 8px;
			margin-left: 10px;
			color: #07a287;
			background-color: rgba(7, 162, 136, 0.1);
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			-ms-border-radius: 8px;
			-o-border-radius: 8px;
			transition: all 0.3s ease;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-ms-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;

			.icon {
				margin-right: 6px;
				font-size: 25px;
				line-height: 18px;
			}

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

.eye__btn,
.edit__btn,
.del__btn {
	margin: 0 4px;
	padding: 6px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	background-color: rgba(41, 159, 227, 0.1);
	color: rgb(41, 159, 227);
	border: 0;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;

	svg {
		width: 20px;
		height: 20px;
	}

	&:hover {
		opacity: 0.7;
	}
}

.del__btn {
	background-color: rgba(255, 82, 82, 0.1);
}

.save__btn,
.close__btn,
.delete__btn,
.filter__btn {
	padding: 10px 14px;
	white-space: nowrap;
	background-color: #07a287;
	color: #fff;
	border-radius: 8px;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;

	&:not(:disabled):hover {
		opacity: 0.7;
	}

	&:disabled {
		opacity: .5;
	}
}

.close__btn {
	background-color: #2323231c;
	color: #000;
}

.filter__btn {
	background-color: #29a0e3;
	color: #fff;
}

.delete__btn {
	display: flex;
	align-items: center;
	background-color: #ff5252;
}

.err__back {
	background-color: rgba(255, 82, 82, 0.1) !important;
}

.err__mes {
	font-size: 13px;
	color: red;
}

.mark__btn,
.mark__active,
.mark__inactive,
.info__btn {
	padding: 5px 15px;
	font-size: 14px;
	white-space: nowrap;
	background: rgba(7, 162, 135, 0.1);
	color: #07a287;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;

	a {
		color: #07a287;
	}

	&:hover {
		opacity: 0.7;
	}
}

.mark__inactive {
	background: rgba(238, 131, 140, 0.1);
	color: red;
}

.info__btn {
	background: rgba(41, 160, 227, 0.1);
	color: #29a0e3;
}

.primary__btn,
.success__btn {
	padding: 6px 12px;
	background-color: rgba(41, 160, 227, 0.1);
	color: #29a0e3;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;

	&:hover {
		opacity: 0.7;
	}
}

.success__btn {
	background-color: rgba(7, 162, 135, 0.1);
	color: #07a287;
}

.notSuccess__btn {
	background-color: rgba(255, 82, 82, 0.1);
	color: #ef6e85;
}

.select {
	padding: 12px;
	background-color: rgba(51, 51, 51, 0.05);
	color: #495057;
	border-radius: 8px;
	border: 0;

	&:focus-visible {
		outline: none;
	}
}

.form {
	label {
		width: 100%;
		color: #a7a7a7;

		.lab {
			margin-bottom: 8px;
			font-size: 15px;

			@media (min-width: 1660px) {
				font-size: 16px;
			}
		}
	}

	input,
	textarea,
	select {
		width: 100%;
		padding: 12px;
		background-color: rgba(51, 51, 51, 0.05);
		border-radius: 8px;
		border: 0;

		&::placeholder {
			color: #bcbcbc;
		}
	}

	select {
		padding: 6px 8px;
		color: #495057;

		&:focus-visible {
			outline: none;
		}
	}

	textarea {
		min-height: 180px;
	}
}

.modalBtns {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 15px;
}

.isDone {
	font-weight: 500;
	color: #07a287;
}

.isNotDone {
	font-weight: 500;
	color: #ff5252;
}

.isWaiting {
	color: rgb(46, 143, 233);
}

.accordion__icon {
	transform: rotate(0deg);
	transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);

	&__active {
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
	}
}

@media print {
	.removeOnPrint {
		display: none;
	}
}
