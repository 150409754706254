.navbar {
	&__main {
		width: 300px;
		position: fixed;
		height: 100vh;
		min-height: 100vh;
		overflow-y: scroll;
		overflow-x: hidden;
		top: 0;
		left: 0;
		z-index: 10;
		background-color: #07a287;

		h5 {
			font-size: 18px;
		}

		span {
			font-weight: 500;

			@media (min-width: 1900px) {
				font-size: 19px;
			}
		}

		.nav {
			position: relative;
			padding: 0 20px;

			.navbar__head {
				padding: 20px 0 10px;
				text-align: center;

				.navbar__logo {
					img {
						width: 55px;
					}
				}
			}

			.navbar__toggler {
				position: fixed;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 36px;
				height: 36px;
				top: 132px;
				left: 282px;
				z-index: 11;
				border-radius: 50%;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				background-color: #fff;
				border: 2px solid #07a287;
				cursor: pointer;

				&__icon {
					transform: rotateZ(-90deg);
					-webkit-transform: rotateZ(-90deg);
					-moz-transform: rotateZ(-90deg);
					-ms-transform: rotateZ(-90deg);
					-o-transform: rotateZ(-90deg);
					transition: all 0.4s ease;
					-webkit-transition: all 0.4s ease;
					-moz-transition: all 0.4s ease;
					-ms-transition: all 0.4s ease;
					-o-transition: all 0.4s ease;

					&__act {
						transform: rotateZ(90deg);
						-webkit-transform: rotateZ(90deg);
						-moz-transform: rotateZ(90deg);
						-ms-transform: rotateZ(90deg);
						-o-transform: rotateZ(90deg);
					}
				}
			}

			.links {
				width: calc(100% - 30px);
				list-style: none;
				position: absolute;
				top: 180px;
				left: 15px;
				padding-left: 0;

				.list_link {
					width: 100% !important;
					color: #fff;
					margin: 10px 0;
					text-decoration: none;

					&:hover {
						text-decoration: none;
					}

					&:focus {
						box-shadow: none;
					}
				}

				.link {
					width: 100%;
					font-size: 17px;
					display: flex;
					align-items: center;
					margin-bottom: 5px;
					color: #fff;
					padding: 12px 15px;
					border-radius: 6px;
					-webkit-border-radius: 6px;
					-moz-border-radius: 6px;
					-ms-border-radius: 6px;
					-o-border-radius: 6px;
					transition: all 0.2s ease;
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-ms-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;

					li {
						width: 100%;
						display: flex;
						align-items: center;
					}

					&__active {
						background-color: #008e76;
					}

					&:hover {
						opacity: 0.8;
					}
				}

				.view {
					width: 150%;
					position: relative;
					cursor: pointer;
					padding: 5px 0;
					color: #fff;

					&__icon {
						width: fit-content;
						padding: 12px 15px;
						border-radius: 8px;
						-webkit-border-radius: 8px;
						-moz-border-radius: 8px;
						-ms-border-radius: 8px;
						-o-border-radius: 8px;
					}

					&__item {
						position: fixed;
						width: 250px;
						padding: 10px;
						margin-top: -58px;
						margin-left: 75px;
						border: none;
						border-radius: 0 5px 5px 0;
						background-color: #07a287;
						display: none;
						z-index: 11;
						-webkit-border-radius: 0 5px 5px 0;
						-moz-border-radius: 0 5px 5px 0;
						-ms-border-radius: 0 5px 5px 0;
						-o-border-radius: 0 5px 5px 0;
					}

					&:hover {
						.view__item {
							display: block;
						}
					}
				}
			}
		}
	}

	::-webkit-scrollbar {
		width: 0;
	}
}
