.wrapper {

}

.detail {
	margin-bottom: 15px;
}

.title {
	font-weight: 500;
	font-size: 16px;
}

.value {
	font-size: 14px;
	color: #4b4646;
}

.links {

}

.link {
	width: 100%;
	padding: 8px;
	text-align: center;
	background-color: rgba(41, 160, 227, 0.1);
	color: rgba(41, 160, 227, 1);
	border-radius: 6px;

	&:not(:last-child) {
		margin-bottom: 5px;
	}
}

.btns {
	display: flex;
	align-items: center;
	column-gap: 20px;
}

.btn {
	width: 100%;
	padding: 8px 12px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;

	&__red {
		background-color: rgba(255, 82, 82, .1);
		color: #ff5252;
	}

	&__green {
		background-color: rgba(7, 162, 135, .1);
		color: #07a287;
	}
}

.loading {
	display: grid;
	place-items: center;
	height: 240px;
}