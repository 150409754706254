.list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.item {
    box-sizing: border-box;
    padding: 15px;
    border: 1px solid #a7a7a7;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
}


.item.selected,
.item:hover {
    background-color: #6993ff;
    color: #fff;
    border-color: #6993ff;
}

.row {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.rowKey {
    font-weight: 600;
    font-size: 18px;
}

.rowValue {
    font-weight: 500;
    font-size: 16px;
}