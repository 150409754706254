.button {
	color: #fff;
	padding: 8px 12px;
	border: none;
	font-size: 14px;
	display: flex;
	align-items: center;
	background: #07a287;
	border-radius: 8px;
	margin-right: 10px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	svg {
		margin-right: 6px;
	}

	&:hover {
		opacity: 0.7;
	}
}
