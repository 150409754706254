.cardItem {
	width: 33.3333%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 13px;
	margin: 0 5px;

	p {
		margin: 0;
		margin-top: 10px;
		color: #fff;
		text-align: center;
		font-weight: 500;
	}

	@media (min-width: 1440px) {
		font-size: 16px !important;
	}
}
